/* --- General Form Styles --- */

/* Form container for date pickers */
.title21-form-container {
    margin-bottom: 20px;
    align-items: left;
    /* Space between form and other elements */
}

/* Grouping date inputs for alignment */
.title21-form-group {
    display: flex;
    align-items: center;
    /* gap: 15px; */
    /* Use gap for consistent spacing */
    gap: 10px;
    /* Full width alignment */
}

.form-label {
    font-weight: bold;
    /* Make the label bold */
    margin-bottom: 0;
    /* Remove unnecessary bottom margin */
}

/* Individual date input styling */
.title21-date-input {
    display: flex;
    align-items: center;
    margin: 0 10px;
    /* Horizontal spacing */
}

/* Labels for date inputs */
.title21-date-input label {
    font-weight: bold;
    margin-right: 8px;
    /* Space between label and input */
}

/* Input styling with padding and responsive size */
.title21-date-input input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: auto;
    min-width: 150px;
    max-width: 200px;
    /* Prevent large expansion */
    box-sizing: border-box;
    /* Consistent padding and border calculations */
}

/* --- Table Styles --- */

/* Date column */
.title21-viewer th.Date-column,
.title21-viewer td.date-column {
    text-align: right;
    /* Align date to the right */
    width: 12%;
    /* Set a maximum width for the date column */
}

/* Sub-chapter column */
.title21-viewer th.Subchapter-column,
.title21-viewer td.sub-chapter-column {
    text-align: center;

    width: 10%;
    /* Maximum width for sub-chapter */
}

/* Part column */
.title21-viewer th.Part-column,
.title21-viewer td.part-column {
    text-align: center;

    width: 10%;
    /* Maximum width for part */
}

/* Sub-part column */
.title21-viewer th.Subpart-column,
.title21-viewer td.sub-part-column {
    text-align: center;

    width: 10%;
    /* Maximum width for sub-part */
}

/* Regulation column */
.title21-viewer th.regulation-column,
.title21-viewer td.regulation-column {
    text-align: left;
    width: 50%;
    /* Set maximum width to 60% of the table */
}

/* Details column */
.title21-viewer th.Details-column,
.title21-viewer td.details-column {
    text-align: center;
    /* Left align details */
    
    /* Minimum width for details */
    width: 8%;
    /* Maximum width for details */
}